<template>
  <div class="scroll-anim">
    <div class="scroll-anim__col">
      <div v-for="(article, i) in config.texts" :key="i">
        <h2 class="scroll-anim__title">{{ article.title }}</h2>
        <p class="scroll-anim__text p-3 reg">{{ article.text }}</p>
      </div>
    </div>

    <div :id="config.scrollId" class="scroll-anim__col">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-scroll-anim",
  props: {
    config: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.scroll-anim {
  position: relative;
  color: $white;
  display: flex;
  justify-content: space-between;

  &--reverse {
    flex-direction: row-reverse;
  }

  &__col {
    position: relative;
    width: 48%;

    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:last-child {
      display: flex;
      align-items: center;

      @include mobile {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: $space-32;

    @include tablet {
      margin-bottom: $space-16;
      font-size: 32px;
    }
  }

  &__text {
    @include tablet {
      font-size: 16px;
    }
  }
}
</style>
